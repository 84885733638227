import apiInstance from './api';

const orderUnitProcess = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    updateOrderDate: build.mutation({
      query: ({ id, body }) => ({
        url: `/ou_process_tracks/additional_time/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOfferDetail', 'ArticleOffers'],
    }),
    orderUnitDecision: build.mutation({
      query: ({ id, body }) => ({
        url: `/article_offers/decision/ou_control/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'AuthorArticles'],
    }),
  }),
  overrideExisting: true,
});

export default orderUnitProcess;
